
import { Options, Vue } from 'vue-class-component'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import Menu from '@/components/Menu.vue'
import ContactExternal from '@/components/ContactExternal.vue'
import ContactPersonal from '@/components/ContactPersonal.vue'

@Options({
  components: {
    BootstrapIcon,
    ContactExternal,
    ContactPersonal,
    Menu
  }
})
export default class Contact extends Vue {
  entryMode = ''
}
