
import { useRoute } from 'vue-router'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import TownMap from '@/components/TownMap.vue'
import Menu from '@/components/Menu.vue'
import Credits from '@/components/Credits.vue'
import ContentModal from '@/components/ContentModal.vue'
import { getTown, MediaFilter, TownData } from '@/api'
import { defineComponent } from 'vue'
import NoUiSlider from '@/components/NoUiSlider.vue'
import { CountPips, Options, PipsMode } from 'nouislider'

export default defineComponent({
  components: {
    BootstrapIcon,
    ContentModal,
    Credits,
    TownMap,
    Menu,
    NoUiSlider
  },

  data () {
    return {
      currentRoute: useRoute(),
      mapDataReady: false,
      filterVisible: false,
      filter: new MediaFilter(),
      error: false,
      town: undefined as TownData | undefined,
      prevTown: undefined as TownData | undefined,
      nextTown: undefined as TownData | undefined,
      initialDate: new Date('2021-07-13T00:00:00')
    }
  },
  computed: {
    currentTownName (): string {
      return this.town ? this.town.name : this.ucfirst(this.routeName)
    },

    routeName (): string {
      let routeName: string
      if (typeof this.$route.params.name === 'string') {
        routeName = this.$route.params.name
      } else {
        routeName = this.$route.params.name[0]
      }

      return routeName
    }
  },

  methods: {
    drawHeader (): void {
      this.error = false
      getTown(this.routeName).then(town => {
        this.town = town
        this.mapDataReady = true

        // prev town name
        getTown(town.prev).then(prevTown => {
          this.prevTown = prevTown
        })

        // next town name
        getTown(town.next).then(nextTown => {
          this.nextTown = nextTown
        })
      }).catch(() => {
        this.error = true
      })
    },
    ucfirst (s: string): string {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    updateBinaryFilters (): void {
      this.filter.format = []
      if (document.querySelector('#filter button.btn-success[data-toggle="format:image"]')) this.filter.format.push('image')
      if (document.querySelector('#filter button.btn-success[data-toggle="format:video"]')) this.filter.format.push('video')
      this.filter.type = []
      if (document.querySelector('#filter button.btn-success[data-toggle="type:img"]')) this.filter.type.push('img')
      if (document.querySelector('#filter button.btn-success[data-toggle="type:twitter"]')) this.filter.type.push('twitter')
      if (document.querySelector('#filter button.btn-success[data-toggle="type:reddit"]')) this.filter.type.push('reddit')
      if (document.querySelector('#filter button.btn-success[data-toggle="type:iframe"]')) this.filter.type.push('iframe')
      if (document.querySelector('#filter button.btn-success[data-toggle="type:youtube"]')) this.filter.type.push('youtube')
      if (document.querySelector('#filter button.btn-success[data-toggle="type:link"]')) this.filter.type.push('link')
      this.filter.triggerEvent()
    },
    toggleButton (e: Event): void {
      let button = e.target as HTMLElement
      while (button && button.tagName.toLowerCase() !== 'button') button = button.parentElement as HTMLElement
      if (!button) return
      button.classList.toggle('btn-success')
      button.classList.toggle('btn-danger')
    },
    generateSliderConfig (): Options {
      const dayOffset = (date: Date) => Math.floor((date.getTime() - this.initialDate.getTime()) / 86400000)
      const days = dayOffset(new Date())
      const formatter = {
        to: (num: number) => {
          const d = new Date(this.initialDate.getTime() + 86400000 * num)
          return `${String(d.getDate()).padStart(2, '0')}.${String(d.getMonth() + 1).padStart(2, '0')}.${d.getFullYear()}`
        },
        from: (str: string) => {
          const parts = str.split('.')
          const d = new Date()
          d.setFullYear(Number.parseInt(parts[2], 10))
          d.setMonth(Number.parseInt(parts[1], 10) - 1)
          d.setDate(Number.parseInt(parts[0], 10))
          return dayOffset(d)
        }
      }
      const options = {
        start: [0, days],
        step: 1,
        connect: true,
        range: {
          min: 0,
          max: days
        },
        tooltips: [formatter, formatter],
        pips: {
          mode: PipsMode.Count,
          density: 6,
          values: 4,
          format: formatter
        } as CountPips
      }
      console.log(options)
      return options
    },
    updateSlider (vals: string[2]): void {
      const since = new Date(this.initialDate.getTime() + 86400000 * Number.parseInt(vals[0]))
      const before = new Date(this.initialDate.getTime() + 86400000 * Number.parseInt(vals[1]))
      this.filter.since = since
      this.filter.before = before
      this.filter.triggerEvent()
    }
  },
  created (): void {
    // watch for route update if navigating between towns
    this.$watch(
      () => this.$route.params,
      () => {
        this.drawHeader()
      },
      {
        // trigger once on component creation
        immediate: true,
        // https://github.com/vuejs/vue-next/issues/2291 *sigh*
        flush: 'post'
      }
    )
  }
})
