
import { Options, Vue } from 'vue-class-component'
import Map from '@/views/Map.vue'
import BigButton from '@/components/BigButton.vue'
import Credits from '@/components/Credits.vue'

@Options({
  components: {
    BigButton,
    Credits,
    Map
  }
})
export default class Home extends Vue {
  scrollToMap (): void {
    const map = document.getElementById('map')
    if (map) map.scrollIntoView()
  }
}
