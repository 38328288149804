
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    id: String,
    size: {
      type: String,
      default: 'md'
    },
    minHeight: {
      type: Boolean,
      default: false
    }
  }
})
export default class Modal extends Vue {
  id!: string
  size!: 'sm' | 'md' | 'lg' | 'xl'
  minHeight!: boolean
}
