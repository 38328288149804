
import { Options, Vue } from 'vue-class-component'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'

@Options({
  components: { BootstrapIcon },
  props: {
    type: String,
    image: {
      type: Boolean,
      required: false,
      default: false
    },
    video: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      required: false,
      default: {}
    }
  }
})
export default class ContentRef extends Vue {
  type!: string
  image!: boolean
  video!: boolean
  // eslint-disable-next-line
  data!: any
}
