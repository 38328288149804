
import { Options, Vue } from 'vue-class-component'
import GDPRModal from '@/components/GDPRModal.vue'

@Options({
  components: {
    GDPRModal
  }
})
export default class App extends Vue {
}
