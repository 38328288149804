
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    route: String,
    position: String,
    x: Number,
    y: Number
  }
})
export default class MapMarker extends Vue {
  route!: string
  position!: string
  x!: number
  y!: number
}
