
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    closeButton: {
      type: Boolean,
      default: true
    }
  }
})
export default class ModalHeader extends Vue {
  closeButton!: boolean
}
