
import { Options, Vue } from 'vue-class-component'
import Ahr from '@/components/Ahr.vue'
import MapMarker from '@/components/MapMarker.vue'
import { getTowns, TownData } from '@/api'

@Options({
  components: {
    MapMarker,
    Ahr
  },
  props: {
    towns: {
      type: Array
    }
  }
})
export default class Map extends Vue {
  towns!: TownData[]
  internalTowns: TownData[] = []

  beforeCreate (): void {
    if (this.towns.length > 0) {
      this.internalTowns = this.towns
    } else {
      getTowns().then(t => {
        this.internalTowns = t
      })
    }
  }

  get computedTowns (): TownData[] {
    return this.internalTowns
  }
}
