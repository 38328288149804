
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    url: String,
    height: Number
  }
})
export default class IFrameEmbed extends Vue {
  url!: string
  height!: number
}
