
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    url: String
  }
})
export default class RedditEmbed extends Vue {
  url!: string
}
