
import { Options, Vue } from 'vue-class-component'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'

@Options({
  components: { BootstrapIcon }
})
export default class Menu extends Vue {
  back (): void {
    history.back()
  }
}
