
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  props: {
    tweet: String // This needs to be a string or we need some BigInt conversion logic
  }
})
export default class TwitterEmbed extends Vue {
  tweet!: string
}
