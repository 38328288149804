
import { Options, Vue } from 'vue-class-component'
import Menu from '@/components/Menu.vue'
import ModalLink from '@/components/ModalLink.vue'

@Options({
  components: { ModalLink, Menu }
})
export default class Legal extends Vue {
}
