
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    target: String
  }
})
export default class ModalLink extends Vue {
  target!: string
}
