
import { Options, Vue } from 'vue-class-component'
import ModalBody from '@/components/ModalBody.vue'
import ModalHeader from '@/components/ModalHeader.vue'
import Modal from '@/components/Modal.vue'
import TwitterEmbed from '@/components/TwitterEmbed.vue'
import RedditEmbed from '@/components/RedditEmbed.vue'
import YoutubeEmbed from '@/components/YoutubeEmbed.vue'
import IFrameEmbed from '@/components/IFrameEmbed.vue'
import ImgEmbed from '@/components/ImgEmbed.vue'
import VidEmbed from '@/components/VidEmbed.vue'
import ModalLink from '@/components/ModalLink.vue'
import { postComplaint, postCorrection } from '@/api'
import { LMap, LMarker, LTileLayer } from '@vue-leaflet/vue-leaflet'
import { LatLngLiteral, LatLngTuple } from 'leaflet'

@Options({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    ImgEmbed,
    VidEmbed,
    IFrameEmbed,
    YoutubeEmbed,
    RedditEmbed,
    TwitterEmbed,
    Modal,
    ModalHeader,
    ModalBody,
    ModalLink
  }
})
export default class ContentModal extends Vue {
  size: 'sm' | 'md' | 'lg' | 'xl' = 'lg'
  title = ''
  type: 'twitter' | 'reddit' | 'iframe' | 'youtube' | 'link' | 'img' | 'vid' | 'blank' = 'blank'

  mediaId = -1
  tweetId = -1
  twitterIframeUrl = ''
  youtubeUrl = ''
  redditUrl = ''
  iframeUrl = ''
  iframeHeight = 0
  imgUrl = ''
  imgCopyright = ''
  imgCopyrightUrl = ''
  vidUrl = ''
  vidCopyright = ''
  vidCopyrightUrl = ''

  latitude = 50.446
  longitude = 6.87647
  latitudeNew = this.latitude
  longitudeNew = this.longitude

  complaintStatus = 0
  correctionStatus = 0

  // todo create explicit data type
  // eslint-disable-next-line
  public setContent (id: number, type: 'twitter' | 'reddit' | 'iframe' | 'youtube' | 'link' | 'img' | 'vid' | 'blank', title: string, latlng: LatLngTuple, data: any): void {
    this.mediaId = id
    this.type = type
    this.title = title
    this.complaintStatus = 0

    this.latitude = this.latitudeNew = latlng[0]
    this.longitude = this.longitudeNew = latlng[1]

    switch (type) {
      case 'twitter':
        this.size = 'md'
        this.tweetId = data.id
        this.twitterIframeUrl = data.iframe
        break
      case 'iframe':
        this.size = 'xl'
        this.iframeUrl = data.url
        this.iframeHeight = data.height
        break
      case 'reddit':
        this.size = 'md'
        this.redditUrl = `https://www.redditmedia.com/r/${data.sub}/comments/${data.postId}?ref_source=embed&amp;ref=share&amp;embed=true`
        break
      case 'youtube':
        this.size = 'xl'
        this.youtubeUrl = `https://www.youtube-nocookie.com/embed/${data.videoId}`
        if (data.start) {
          this.youtubeUrl += `?start=${data.start}`
        }
        break
      case 'img':
        this.size = 'xl'
        this.imgUrl = process.env.VUE_APP_STATIC_URL + data.url
        this.imgCopyright = data.copyright ? data.copyright : ''
        this.imgCopyrightUrl = data.copyrightUrl ? data.copyrightUrl : ''
        break
      case 'vid':
        this.size = 'xl'
        this.vidUrl = process.env.VUE_APP_STATIC_URL + data.url
        this.vidCopyright = data.copyright ? data.copyright : ''
        this.vidCopyrightUrl = data.copyrightUrl ? data.copyrightUrl : ''
    }
  }

  sendComplaint (e: Event): void {
    e.preventDefault()
    if (e.target instanceof HTMLFormElement) {
      const formData = new FormData(e.target)
      const formObject = Object.fromEntries(formData)
      postComplaint(formObject).then(response => {
        if (response.status < 400) {
          this.complaintStatus = 1
          const form = document.querySelector('#complaintModal form')
          if (form instanceof HTMLFormElement) {
            form.reset()
          }
        } else {
          this.complaintStatus = 2
        }
      }).catch(() => {
        this.complaintStatus = 3
      })
    }
  }

  sendCorrection (e: Event): void {
    e.preventDefault()
    if (e.target instanceof HTMLFormElement) {
      const formData = new FormData(e.target)
      const formObject = Object.fromEntries(formData)
      postCorrection(formObject).then(response => {
        if (response.status < 400) {
          this.correctionStatus = 1
          const form = document.querySelector('#correctionModal form')
          if (form instanceof HTMLFormElement) {
            form.reset()
          }
        } else {
          this.correctionStatus = 2
        }
      }).catch(() => {
        this.correctionStatus = 3
      })
    }
  }

  correctionMarkerMove (e: { latlng: LatLngLiteral }): void {
    this.latitudeNew = e.latlng.lat
    this.longitudeNew = e.latlng.lng
  }

  mounted (): void {
    const m = document.getElementById('contentModal')
    // eslint-disable-next-line
    const component = this
    if (m) {
      m.addEventListener('hidden.bs.modal', () => {
        component.setContent(-1, 'blank', '', [50.44881, 6.88879], {})
      })
    }

    // Recalculate map container with invalidateSize() after the correction modal is opened
    const correctionModal = document.getElementById('correctionModal')
    if (correctionModal) {
      correctionModal.addEventListener('shown.bs.modal', () => {
        var correctionMap = (this.$refs.leafletCorrectionMap as LMap).leafletObject
        correctionMap.invalidateSize()
      })
    }
  }
}
