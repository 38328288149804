
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    url: String,
    alt: {
      type: String,
      required: false,
      default: ''
    },
    copyright: {
      type: String,
      required: false,
      default: ''
    },
    copyrightUrl: {
      type: String,
      required: false,
      default: ''
    }
  }
})
export default class ImgEmbed extends Vue {
  url!: string
  alt!: string
  copyright!: string
  copyrightUrl!: string
}
