
import { Options, Vue } from 'vue-class-component'

@Options({
  emits: {
    click: null
  }
})
export default class BigButton extends Vue {
  emitClick (e: Event): void {
    this.$emit('click', e)
  }
}
