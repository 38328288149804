
import { Options, Vue } from 'vue-class-component'
import { postContact } from '@/api'
import ModalLink from '@/components/ModalLink.vue'

@Options({
  components: {
    ModalLink
  }
})
export default class ContactExternal extends Vue {
  formStatus = 0

  sendForm (e: Event): void {
    e.preventDefault()
    if (e.target instanceof HTMLFormElement) {
      const formData = new FormData(e.target)
      const formObject = Object.fromEntries(formData)
      postContact(formObject).then(response => {
        if (response.status < 400) {
          this.formStatus = 1
          const form = document.querySelector('#complaintModal form')
          if (form instanceof HTMLFormElement) {
            form.reset()
          }
        } else {
          this.formStatus = 2
        }
      }).catch(() => {
        this.formStatus = 3
      })
    }
  }
}
