
import { Options, Vue } from 'vue-class-component'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import ModalLink from '@/components/ModalLink.vue'

@Options({
  components: {
    ModalLink,
    BootstrapIcon
  }
})
export default class Credits extends Vue {
}
